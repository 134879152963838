import { useAccount } from 'hooks/use-account';

import { pageClickEvent } from '../../lib/eventstream/event-catalog';
import {
  LOGOUT_URL,
  SQ_FACEBOOK_URL,
  SQ_INSTAGRAM_URL,
  SQ_TWITTER_URL,
  TERMS_URL,
  BUYER_PRIVACY_URL,
} from 'lib/constants';
import ES2Tracker from 'lib/eventstream/tracker';

import styles from './footer.module.css';
import { useRegion } from 'hooks/use-region';

const Footer = () => {
  const { account } = useAccount();
  const { selectedRegion } = useRegion();

const trackSellerSupportClick = () => {
  ES2Tracker.trackPageClick(
    pageClickEvent(
      'button-click',
      `Marketing: Buyer: Marketplace: Square Help: ${selectedRegion}`,
    ),
  );
};

  return (
    <div className={styles.footerContainer}>
      <footer className={styles.wrapper}>
        <div className={`${styles.row} ${styles.alignStart} ${styles.mainRow}`}>
          <div className={styles.leads}>
            <div className={styles.title}>Need help?</div>
            <p className={styles.description}>
              We’ve complied some of our frequently asked questions to help you
              out. For all other inquiries, questions, or concerns, please&nbsp;
              <a
                className={styles.linkSecondary}
                onClick={trackSellerSupportClick}
                href="mailto:squarelocaloffers@squareup.com"
                rel="noreferrer"
              >
                contact support.
              </a>
            </p>
          </div>
        </div>
        <hr className={styles.space} />
        <div
          className={`${styles.row} ${styles.spaceBetween} ${styles.alignStart} ${styles.border}`}
        >
          <div>
            <p className="paragraph-10">Powered by Square &nbsp;</p>
            <img
              src="/icons/powered-by-square.svg"
              alt=""
              width={11}
              height={11}
            />
          </div>
          <div>
            <a
              href={SQ_FACEBOOK_URL}
              className={styles.logoWrapper}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/icons/facebook-white.svg"
                alt="Facebook Logo"
                width={24}
                height={21}
              />
            </a>
            <a
              href={SQ_INSTAGRAM_URL}
              className={styles.logoWrapper}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/icons/instagram-white.svg"
                alt="Instagram Logo"
                width={24}
                height={21}
              />
            </a>
            <a
              href={SQ_TWITTER_URL}
              className={styles.logoWrapper}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/icons/twitter-white.svg"
                alt="Twitter Logo"
                width={24}
                height={21}
              />
            </a>
          </div>
        </div>
        <br />
        <div
          className={`${styles.row} ${styles.spaceBetween} ${styles.alignStart}`}
        >
          <p className="paragraph-20">
            &copy; {new Date().getFullYear()} Block, Inc.
          </p>
          <div className={`${styles.terms} paragraph-20`}>
            <a
              className={styles.link}
              href={TERMS_URL}
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>
            <span>&nbsp;|&nbsp;</span>
            <a
              className={styles.link}
              href={BUYER_PRIVACY_URL}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            <br className={styles.hideOnDesktop} />

            {account && (
              <>
                <span className={styles.hideOnMobile}>&nbsp;|&nbsp;</span>
                <a className={styles.link} href={LOGOUT_URL} rel="noreferrer">
                  Sign out
                </a>
              </>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
